import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "SSL Management",
  sectionHeader: "goSystem secures the internet",
  sectionContent:
    "With goSystem, you never need to worry about web security again. Our self-managed or managed SSL services ensure all your web traffic is secure, giving you and your customers peace of mind when using your web services.\n\nOur SSL certificates are not only the best value but are trusted and verified by industry leaders in SSL/TLS security. ",
  splitTag: "Dependable security",
  splitHeading: "Security that you can depend on",
  splitContent:
    "Our automatic SSL certificate renewal service ensures you are never left without a certificate. It's so easy you can get set-up in as little as 10 minutes! From a single domain with one certificate, to multiple wildcard SSL certificates over many domains, our easy to use system is flexible and scalable.",
  splitImg: "Cloud",
  callToAction: "Get secure with goSystem",
};

export default () => ServicesTemplate(config);
